import './App.sass';

import logo from './assets/logo.svg';

function App() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="App">
      <div className='text-content'>
        <h1>Housing,<br/>Scaled.</h1>
        <p>
          Aeru's mission is to cut the 
          construction cost of affordable 
          housing by 80% with the use of 
          efficient design, alternative 
          materials, automation, and 
          embedded tools.
        </p>
        <footer>Aeru Industries © 2016-{currentYear}</footer>
      </div>
      <img className='logo' src={logo}/>
    </div>
  );
}

export default App;
